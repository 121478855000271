import DeveloperService from '@/services/developer.service'
import OauthService from '@/services/oauth.service'
import { Integration } from '@/models/developers/Integration.js'
import { Plugin } from '@/models/developers/Plugin.js'
import { InlineConnection } from '@/models/developers/InlineConnection'
import SelectIntegration from './SelectIntegration/SelectIntegration.vue'
import SelectPlugin from './SelectPlugin/SelectPlugin.vue'
import { popupCenter } from '@/util/Popup.js'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import KlaviyoConnectionDialog from '@/views/Pages/Integrations/Klaviyo/Dialogs/KlaviyoConnectionDialog.vue'
import EventBus from '@/util/EventBus'

export default {
  name: 'PluginModuleIntegration',
  components: {
    HeaderTopDashboard,
    SelectIntegration,
    SelectPlugin,
    KlaviyoConnectionDialog,
  },
  data () {
    return {
      docTranslations: ['es', 'en', 'pt'],
      integration: new Integration(),
      plugin: new Plugin(),
      inline: new InlineConnection(),
    }
  },
  computed: {
    pluginItems: {
      get: function () {
        const data = []
        for (const item of this.plugin.items) {
          if (item.active) {
            data.push(item)
          }
        }

        return data
      },
    },
    locale () {
      return this.$i18n.locale
    },
  },
  watch: {
    locale: function () {
      this.getPluginModulesAndIntegrations()
    },
  },
  methods: {
    convertToSlug (text) {
      return text
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '')
    },
    seeDoc (url, title) {
      popupCenter(url, title, 1000, 600)
    },
    seeUrl (url, title) {
      popupCenter(url, title, 1000, 600)
    },
    downloadModule (url) {
      window.location.href = url
    },
    connectIntegration (url) {
      window.location.href = url
    },
    disconnectIntegration (itemName) {
      this.disconnectService(itemName)
    },
    getPluginModulesAndIntegrations () {
      const user = JSON.parse(localStorage.getItem('user'))
      const lang = this.$i18n.locale || user.lang
      const selectedTranslation = this.docTranslations.includes(lang) ? lang : 'en'
      const params = {
        lang: selectedTranslation,
      }
      DeveloperService.getPluginModulesAndIntegrations(params)
        .then(
          (response) => {
            const integrations = []
            this.integration.items = []
            for (const key in response.integrations) {
              integrations.push(response.integrations[key])
            }

            this.integration.items = integrations.sort((a, b) => a.order - b.order)
            console.table(this.integration.items)
            this.plugin.items = response.modules
            this.initInlineIntegrations()
          },
          () => {},
        )
        .finally(() => {})
    },
    disconnectService (itemName) {
      OauthService.disconnectService({}, this.convertToSlug(itemName))
        .then(
          (response) => {
            OauthService.successResponse(response.message)
            this.getPluginModulesAndIntegrations()
          },
          (error) => {
            OauthService.errorResponse(
              error.response.status,
              this.$t('Error al intentar desconectar aplicación'),
              this.$t(error.response.data.message),
            )
          },
        )
        .finally(() => {})
    },
    initInlineIntegrations () {
      Object.values(this.integration.items)
        .filter(item => item.type === 'inline')
        .map(item => {
          this.inline.connectionDialogs[item.slug] = false
        })
    },
    showDialog (itemName) {
      return this.inline.connectionDialogs[itemName] || false
    },
    connectInline (itemName) {
      itemName = itemName.toLowerCase()
      this.$set(this.inline.connectionDialogs, itemName, true)
    },
    disconnectInline (item) {
      const itemName = item.name.toLowerCase()
      const serviceName = itemName.charAt(0).toUpperCase() + itemName.slice(1)
      DeveloperService.disconnectInlineIntegrationApiKey({ slug: itemName, apiKey: null })
        .then(() => {
          item.status = false
          EventBus.$emit(
            'showAlert',
            'success',
            this.$t('Se ha desconectado de ') + serviceName,
          )
        }, () => {
          EventBus.$emit(
            'showAlert',
            'danger',
            this.$t('Se produjo un error al desconectar ' + serviceName),
          )
        })
    },
    onCloseDialog (itemName) {
      itemName = itemName.toLowerCase()
      this.$set(this.inline.connectionDialogs, itemName, false)
    },
    onConnected (itemName) {
      itemName = itemName.toLowerCase()
      this.integration.items[itemName].status = true
    },
  },
  created () {
    this.getPluginModulesAndIntegrations()
  },
}
