import { Service } from './service'

const PATH = 'oauth/'

class OauthService extends Service {
  connectService (data = {}, integration) {
    return this.post(PATH + 'connect/' + integration, data)
  }

  disconnectService (params = {}, integration) {
    return this.get(PATH + 'disconnect/' + integration, params)
  }
}

export default new OauthService()
