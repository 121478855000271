import DeveloperService from '@/services/developer.service'
import { popupCenter } from '@/util/Popup.js'

export default {
  name: 'SelectIntegration',
  props: {
    integration: {
      type: Object,
      require: true,
    },
  },
  data () {
    return {
      popup: false,
    }
  },
  methods: {
    toogle () {
      this.integration.itemSelectedShow = !this.integration.itemSelectedShow
    },
    seeDoc (url, title) {
      popupCenter(url, title, 1000, 600)
    },
    viewLink (url, title) {
      popupCenter(url, title, 1000, 600)
    },
    connectIntegration (url, title) {
      popupCenter(url, title, 1000, 600)
    },
    disconnectIntegration (title) {
      this.disconnectService(title)
    },
    convertToSlug (text) {
      return text
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '')
    },
    getPluginModulesAndIntegrations () {
      const user = JSON.parse(localStorage.getItem('user'))
      const params = {
        lang: user.lang,
      }
      DeveloperService.getPluginModulesAndIntegrations(params)
        .then(
          (response) => {
            this.integration.items = response.integrations
          },
          () => {},
        )
        .finally(() => {})
    },
    disconnectService (title) {
      const data = {
        integration: this.convertToSlug(title),
      }
      DeveloperService.disconnectService(data)
        .then(
          () => {
            this.getPluginModulesAndIntegrations()
          },
          () => {},
        )
        .finally(() => {})
    },
  },
}
