import { popupCenter } from '@/util/Popup.js'

export default {
  name: 'SelectPlugin',
  props: {
    plugin: {
      type: Object,
      require: true,
    },
  },
  data () {
    return {
    }
  },
  methods: {
    toogle () {
      this.plugin.itemSelectedShow = !this.plugin.itemSelectedShow
    },
    seeUrl (url, title) {
      popupCenter(url, title, 1000, 600)
    },
    downloadPlugin (url, title) {
      popupCenter(url, title, 1000, 600)
    },
  },
}
