export default {
  name: 'ConnectionDialog',
  props: {
    slugId: {
      type: String,
      required: true,
    },
    showDialog: {
      type: Boolean,
      required: true,
    },
  },
  components: {
  },
  data: function () {
    return {

    }
  },
  methods: {
    closeDialog () {
      this.$emit('close', this.slugId)
    },
  },
}
