import DeveloperService from '@/services/developer.service'
import EventBus from '@/util/EventBus'
import ConnectionDialog from './ConnectionDialog.vue'

export default {
  name: 'KlaviyoConnectionDialog',
  props: {
    showDialog: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ConnectionDialog,
  },
  data: function () {
    return {
      slugId: 'klaviyo',
      username: '',
      apiKey: '',
      show1: false,
    }
  },
  methods: {
    closeDialog (slugId) {
      this.$emit('close', slugId)
    },
    connectWithKlaviyo () {
      DeveloperService.connectInlineIntegrationApiKey({ slug: this.slugId, apiKey: this.apiKey })
        .then(() => {
          EventBus.$emit(
            'showAlert',
            'success',
            this.$t('Se ha registrado la API Key de Klaviyo'),
          )
          this.apiKey = ''
          this.$emit('connected', this.slugId)
          this.closeDialog(this.slugId)
        }, () => {
          EventBus.$emit(
            'showAlert',
            'danger',
            this.$t('Se produjo un error al registrar la API Key de Klaviyo'),
          )
        })
    },
    disconnectKlaviyo () {
      DeveloperService.disconnectInlineIntegrationApiKey({ slug: this.slugId, apiKey: null })
        .then(() => {
          EventBus.$emit(
            'showAlert',
            'success',
            this.$t('Se ha desconectado de Klaviyo'),
          )
          this.closeDialog(this.slugId)
        }, () => {
          EventBus.$emit(
            'showAlert',
            'danger',
            this.$t('Se produjo un error al desconectar Klaviyo'),
          )
        })
    },
  },
}
